<template>
  <div class="cart-wishlist">
    <div class="cart-wishlist-header">
      <div class="cart-wishlist-header__left">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="5" fill="#F3F4F6" />
          <g clip-path="url(#clip0)">
            <g clip-path="url(#clip1)">
              <path d="M18 19.5L13.5 15L18 10.5" stroke="#333333" stroke-width="2" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="12" height="12" fill="white" transform="matrix(0 -1 -1 0 21 21)" />
            </clipPath>
            <clipPath id="clip1">
              <rect width="12" height="12" fill="white" transform="translate(9 9)" />
            </clipPath>
          </defs>
        </svg>
        <SfHeading
          :title="$t('Wishlist') + ' (' + products.length + ')'"
          :level="3"
          class="cart-wishlist-header__title"
        />
      </div>
      <div class="cart-wishlist-header__right">
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-transaction-name="Wishlist - Create New Group"
          @click="openModalCreateNewGroupWishlist"
        >
          <rect width="30" height="30" rx="5" fill="#F3F4F6" />
          <path d="M15.5 8V15.5M15.5 15.5H8M15.5 15.5V23M15.5 15.5H23" stroke="#333333" />
        </svg>
      </div>
    </div>
    <div class="cart-wishlist-content">
      <OCartWishlist class="cart-wishlist-header__right" :is-page="true" />
    </div>
  </div>
</template>

<script>
import OCartWishlist from 'theme/components/organisms/o-cart-wishlist'
import { SfHeading } from '@storefront-ui/vue'
import { mapActions, mapGetters } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';

export default {
  name: 'MinicartWishlist',
  components: {
    OCartWishlist,
    SfHeading
  },
  computed: {
    ...mapGetters({
      wishlistGroups: 'wishlist/getGroups'
    }),
    products () {
      return [].concat(...this.wishlistGroups.map(group => group.items || []))
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      setModeCreateNewGroup: 'wishlist/setModeCreateNewGroup'
    }),
    async openModalCreateNewGroupWishlist () {
      await this.setModeCreateNewGroup(true)
      this.openModal({ name: ModalList.CartToGroupWishlist, payload: { isModeCreateNewGroup: true } })
    }
  },
  metaInfo () {
    return {
      title: this.$i18n.t('Favorites')
    };
  },
  beforeMount () {
    this.$store.dispatch('wishlist/setModeCreateNewGroup', true)
  },
  beforeDestroy () {
    this.$store.dispatch('wishlist/setModeCreateNewGroup', false)
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.cart-wishlist {
  margin-bottom: 80px;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: var(--spacer-15) var(--spacer-20);
    margin-bottom: var(--spacer-15);
    border-bottom: 2px solid var(--light-gray);

    &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__title {
      --heading-title-color: var(--black);
      --heading-title-font-weight: var(--font-medium);
      --heading-title-font-size: var(--font-size-20);
      --heading-title-font-line-height: var(--font-xl);
      --heading-padding: 0;
      margin-left: var(--spacer-15);
    }
  }

  &-content {
    padding: 0 var(--spacer-10) var(--spacer-10);
  }
}
</style>
